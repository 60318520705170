<template>
  <v-container class="pa-4 text-center">
    <v-row>
      <v-col cols="12">
        <h1  style="font-size: 50px" class="d-none d-md-block text-center">{{title}}</h1>
        <h1  class="d-md-none text-center">{{title}}</h1>
      </v-col>
    </v-row>
    <PageText :text="p1" />
    <PageQuote :text="q1" person="Albert Einstein" />
    <PageImage path="img/ss-two-ppl-chatting.jpg" />
  </v-container>
</template>

<script>
import PageText from '@/components/PageText.vue';
import PageQuote from '@/components/PageQuote.vue';
import PageImage from '@/components/PageImage.vue';

export default {
  components: { PageText, PageImage, PageQuote },
  data: () => ({
    title: 'Cognitive Behavioral Therapy',
    p1: `
      Cognitive Behavioral Therapy (CBT) is what many people think of when they think about
      “therapy.” CBT is an evidence-based practice, well documented for its effectiveness treating
      a variety of mental health issues. As the name may suggest, CBT examines an individual's
      cognitions (thoughts) and behavior and how these impact one's emotional state and perpetuate
      problems. By aiding clients in identifying and challenging cognitive distortions and
      maladaptive behavioral patterns, I am able to assist clients in finding more adaptive ways to
      cope with their struggles. 
    `,
    q1: `
      “We can’t solve problems by using the same kind of thinking we used when we created them.” 
    `,
  }),
};
</script>
